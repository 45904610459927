<template>
  <div id="proveedor">

    <vx-card class="mb-6" >
      <div class="vx-row">
        <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold rg-title">Mis <span class="orange">solicitantes</span>.</h1>
            <p class="text-subtitle mt-4 black" style="color: black !important;">
              En esta página podrás consultar la lista de solicitantes que has registrado previamente. 
              También podrás crear un proyecto a partir de alguno de los solicitantes, <strong>si las condiciones de su crédito lo permiten.</strong>
            </p>
        </div>
      </div>
    </vx-card>

    <vx-card v-if="activeTableView" title="Lista de solicitantes" class="mb-6">
      <p>A partir de la acción <strong>"crear proyecto"</strong> podrás abrir una nueva solicitud de forma automática.
        En caso de que el proyecto más reciente del solicitante se haya aprobado hace más de 180 días, será necesario proveer de nueva cuenta el <strong>Formato de revisión crediticia</strong> firmado y escaneado.
      </p>
      
      <vs-table ref="applicantsTable" stripe search noDataText="No se han generado registros" :data="applicantsList" pagination :max-items="itemsPerPage">

        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-end">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown v-if="isMounted" vs-trigger-click class="cursor-pointer mr-4 items-per-page-handler">
            <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentItemsPage * itemsPerPage - (itemsPerPage - 1) }} - {{ applicantsList.length - currentItemsPage * itemsPerPage > 0 ? currentItemsPage * itemsPerPage : applicantsList.length }} de {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="itemsPerPage=it" :key="it">
                <span>{{it}}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th class="bold" sort-key="fullname">NOMBRE</vs-th>
          <vs-th class="bold" sort-key="email">EMAIL</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>

        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].fullname">
              {{ data[indextr].fullname }}
            </vs-td>

            <vs-td :data="data[indextr].email">
              {{ data[indextr].email }}
            </vs-td>

            <vs-td :data="data[indextr].id">
              <vs-button v-if="canCreateProject(tr)" type="flat" class="p-2" icon="add_box" @click.stop="createProjectRequest(data[indextr])">Crear proyecto</vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>

    <!-- Formato -->
    <vx-card v-else title="Presolicitud para solicitante existente" class="mb-5">
      <p class="mb-3">Solicitante seleccionado: <strong>{{this.partialCreationClientName}}</strong>.</p>
      <p class="mb-4">Se creará una nueva pre-solicitud a partir de los datos ya existentes del solicitante.
        Sin embargo, es necesario que <strong>se llene de nueva cuenta el formato de revisión crediticia y lo agregues firmado y escaneado.</strong>
        Puedes descargar el formato aquí:</p>
        <download-button
        v-if="person_type != pmTypeValue"
          title="Círculo de Crédito Persona Física o PFAE"
          actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Fisica-o-PFAE.pdf"
          fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Fisica-o-PFAE.pdf"
          :remoteFile="true" />

      <download-button
        v-if="person_type == pmTypeValue"
        title="Círculo de Crédito Persona Moral"
        actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
        fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
        :remoteFile="true" />

      <div class="vx-row">
        <document-upload 
          ref="formato"
          :f="formatDocField.formField"
          :automatic="true"
          :target_id="partialCreationUserId"
          :doc="formatDocField.documentSet"
          :skipLoadedActions="false"
          :initAsEmpty="true"
          />
      </div>

      <!-- Save Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex justify-between flex-wrap">
            <vs-button type="border" class="mt-2" @click="()=> activeTableView = true">Cancelar y volver</vs-button>
            <vs-button class="mt-2" @click="savePartialPreproject()">Enviar</vs-button>                
          </div>
        </div>
      </div>

    </vx-card>

  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper"
import dateHelper from "@mixins/dateHelper";
import formHelper from "@components/mixins/formHelper";
const itemsPerPageDefinitions = [5,10,25,50];

export default {
  mixins: [inputHelper, dateHelper,formHelper],
  components: {
  },
  data(){
    return {
      applicantsList: [],
      isMounted: false,

      itemsPerPageDefinitions: itemsPerPageDefinitions,
      itemsPerPage: itemsPerPageDefinitions[0],
      activeTableView: null,

      onError: false,
      onSuccess: false,
      errorMssg: "",

      existingApplicantId: null,
      errorEmailNotAvailable: null,
      minimumDaysToCreditReview: 180,      
      created_id: null,

      pfTypeValue: 0,
      pfaeTypeValue: 2,
      pmTypeValue: 1,
      person_type_section_id: 25,
      person_type: null,
      form_id_pf: 9,
      form_id_pfae: 10,
      form_id_pm: 11,
      pfFormatDocId: 16,
      pmFormatDocId: 24,
      pf_person_type: 1,
      pm_person_type: 1,
      partialCreationConfirmed: null,
      isPartialCreation: null,
      formatDocField: null,
      partialCreationClientName: null,
      partialCreationUserId: null,
    }
  },
  async created(){
    this.isMounted = false;
    this.showLoading(true);
    this.activeTableView = true;
    await this.getAvailableApplicants();
    this.isMounted = true;
    this.showLoading(false);
  },
  watch: {
    family_group_id(newVal,oldVal){
      if(newVal == null){
        this.getProductGroupsForSelect = [];
      }
      else {
        let collection = this.productGroupsList.filter(f => f.family_group_id == this.family_group_id);
        this.getProductGroupsForSelect = [];
        collection.forEach(opt => {
          this.getProductGroupsForSelect.push({value: opt.id, text: opt.name}); //conversion a sintaxis de vs-select 
        })
      }
    } 
  },
  computed: {
    currentItemsPage () {
      if (this.isMounted) {
        return this.$refs.applicantsTable.currentx;
      }
      return 0
    },
    queriedItems () {
      return this.$refs.applicantsTable ? this.$refs.applicantsTable.queriedResults.length : this.applicantsList.length
    },
  },
  methods: {
    canCreateProject(item){
      return true;
    },
    async getAvailableApplicants(){
      try {
        let res = await axios.get(`/api/v1/supplier/${this.SupplierId}/getSupplierAvailableClients`);
        this.applicantsList = res.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    async createProjectRequest(item){
      this.existingApplicantId = item.id;
      await this.runClientValidation(item);
    },

    async runClientValidation(item){
      this.showLoading(true);
      try {
        let sel = this.applicantsList.find(f => f.id == item.id);
        let res = await axios.post(`/api/v1/supplier/${this.SupplierId}/checkExistingClientForProject`, {client_id: item.id});
        const explanation = res.data.explanation.replace('%name%', sel.fullname);
        if(res.data.result == 'failed') {
          this.$vs.dialog({
            color: "danger",
            title: "Creación de solicitud",
            text: `${explanation}`,
            acceptText: "Cerrar",
          });
        }
        else if(res.data.result == 'partial-passed') {
          this.confirmPartialCreation(sel, explanation);
        }
        else if(res.data.result == 'full-passed') {
          this.confirmFullCreation(sel, explanation);
        }

      } catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },

    confirmPartialCreation(sel, explanation){
      this.$vs.dialog({
        color: "warning",
        type: "confirm",
        title: "Creación de pre-solicitud",
        text: `Confirme que desea crear una pre-solicitud. ${explanation}`,
        acceptText: "Confirmar",
        cancelText: 'Cancelar',
        accept: this.setPreprojectView,
      });
    },

    confirmFullCreation(sel, explanation){
      this.$vs.dialog({
        color: "warning",
        type: "confirm",
        title: "Creación de pre-solicitud",
        text: `Confirme que desea crear una solicitud para el cliente ${sel.fullname}. Todos los datos se llenarán en base a la información provista en el pasado.`,
        acceptText: "Confirmar",
        cancelText: 'Cancelar',
        accept: this.createFullPreproject,
      });
    },
    async setPreprojectView(){
      this.showLoading(true);
      await this.getFormInfoForPartialCreation();
      this.activeTableView = false;
      this.showLoading(false);
    },
    async createFullPreproject(){
      this.showLoading(true);
      let payload = { client_id: this.existingApplicantId };
      let res = await axios.post('/api/v2/projects/createProjectForApplicant', payload);
      if(res.data.success == true){
        this.created_id = res.data.id;
        await this.formSubmitted();
      }
      else {
        this.warn(res.data.error);
        this.errorMssg = res.data.error;
      }
      this.showLoading(false);
    },
    async formSubmitted(){
      let mssg = "Tu proyecto ha sido creado, y está en revisión para ser pre-aprobado. Puedes completar la solicitud mientras tanto si así lo deseas. ";
      mssg += `El ID de tu proyecto es: ${this.created_id}.`;
      this.$vs.dialog({
        color: "success",
        type: "confirm",
        title: "Pre solicitud creada correctamente",
        text: mssg,
        acceptText: "Ver solicitud",
        cancelText: 'Finalizar',
        accept: this.goToProject,
        cancel: this.finish,
        close: this.finish,
      })
    },
    goToProject() {
      this.$router.replace({
        name: "detalleProyecto",
        params: { id: this.created_id }
      });
    },
    finish(){
      this.activeTableView = true;
    },

    async getFormInfoForPartialCreation(){
      try {
        let c = this.applicantsList.find(f => f.id == this.existingApplicantId);
        this.partialCreationClientName = c.fullname;
        this.partialCreationUserId = c.user_id;
        this.person_type = (c.person_type == this.pf_person_type) ? this.pfTypeValue : this.pmTypeValue;
        await this.getFormInfo();
        let doc_id = (c.person_type == this.pf_person_type) ? this.pfFormatDocId : this.pmFormatDocId;
        this.formatDocField = this.documentsFields.find(f => f.documentSet.id == doc_id);
      } catch (error) {
        this.warn(error);
      }      
    },

    async getFormInfo(){
      try{          
          let formId = null;
          switch (this.person_type) {
            case this.pfTypeValue:
              formId = this.form_id_pf;
              break;
            case this.pfaeTypeValue:
              formId = this.form_id_pfae;
              break;
            case this.pmTypeValue:
              formId = this.form_id_pm;
              break;
            default:
              break;
          }
      
        let response = await axios.get("/api/v1/forms/getFormFields/" + formId);
        this.form = response.data.flow;
        this.allSections = response.data.sections;
        this.formSections = [];
        this.documentsFields = [];

        let url = this.ApiDomain + `/storagev3/documents/form/${formId}`;
        let response2 = await axios.get(url);
        this.documentsStates = response2.data;
        
        let vFieldsCount = 0;

        this.allSections.forEach(s => {
          vFieldsCount = 0;
          s.fields.forEach(f => {
           this.formFieldSetter(f, this, true);
           if(this.notDocumentNorPrivate(f)){ vFieldsCount += 1;}

           // check for documents
            if(f.doc_id != null){
              let ff = {formField: f, documentSet: null };
              let ss = this.documentsStates.find(sid => sid.id == s.id);
              if(ss && ss.documents){
                ff.documentSet = ss.documents.find(d => d.id == f.doc_id);
              }
              this.documentsFields.push(ff);
            }
          })

          if(vFieldsCount > 0){
            this.formSections.push(s);
          }
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async savePartialPreproject(){
      let r = await this.$refs.formato.checkForNewFileSaved();
      if(!r){
        this.missingFieldsNotif("Documento pendiente", "Por favor agrega el documento antes de continuar");     
      }
      else {
        try {
          let payload = { client_id: this.existingApplicantId };
          let res = await axios.post('/api/v2/projects/createProjectForApplicant', payload);
          if(res.data.success == true){
            this.created_id = res.data.id;
            await this.formSubmitted();
          }
          else {
            this.warn(res.data.error);
            this.errorMssg = res.data.error;
          }
        }
        catch (error) {
          this.warn(err);
          this.failedOperationNotif();
        }
      }   
    },
  }
}
</script>